import React from "react";
import PropTypes from "prop-types";
import Head from "next/head";

function CustomHead({ title, desc, noIndex }) {
  return (
    <Head>
      <title>{title}</title>
      {desc && <meta name="description" content={desc} />}
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="theme-color" content="#242424" />
      <link rel="apple-touch-icon" href="logo192.png" />
      <link rel="manifest" href="manifest.json" />
      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  );
}
CustomHead.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  noIndex: PropTypes.bool,
};
CustomHead.defaultProps = {
  desc: "",
  noIndex: false,
};

export default CustomHead;
