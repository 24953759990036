import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import pick from "lodash/pick";
import useSWR from "swr";
import {
  List,
  Hash,
  UserPlus,
  Shuffle,
  Star,
  Server,
  Users,
  Play,
} from "lucide-react";
import Image from "next/image";
import { useInView } from "react-intersection-observer";

import useTranslations from "@/utils/useTranslations";
import apiHelper from "@/utils/apiHelper";
import useUserData from "@/utils/useUserData";
import { DISCORD_SERVER_INVITE } from "@/utils/constants";

import Head from "@/components/Head/Head";
import OAuthPopup from "@/components/OAuthPopup/OAuthPopup";
import LoadingDots from "@/components/LoadingDots/LoadingDots";

import css from "./Home.module.scss";

const fetcher = (...args) =>
  apiHelper()
    .get(...args)
    .then((results) => {
      const { data } = results;
      console.info("Stats:", data);
      return data;
    });

function Home() {
  const featureRef = useRef();
  const t = useTranslations("home");
  const { onLogin } = useUserData();

  const onInvite = (params) => {
    const guildId = params.get("guild_id");
    if (!guildId) return;
    onLogin(`/dashboard/guild/${guildId}`);
  };

  const scrollToFeatures = () => {
    if (!featureRef.current) return;
    featureRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Head title={t("title")} desc={t("head.body")} />
      <header className={css.header}>
        <div className={css.titleBox}>
          <h1>{t("head.title")}</h1>
          <p>{t("head.body")}</p>
          <div className={css.buttonRow}>
            <OAuthPopup
              url="/api/invite"
              succeededUrl="/api/invited?"
              onSuccess={onInvite}
              className={css.main}
            >
              {t("head.button.add")}
            </OAuthPopup>
            <a
              className={css.sub}
              href={DISCORD_SERVER_INVITE}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("head.button.support")}
            </a>
            <button
              className={css.gray}
              type="button"
              onClick={scrollToFeatures}
            >
              {t("head.button.more")}
            </button>
          </div>
        </div>
        <div>
          <StatsBox />
        </div>
      </header>
      <FeatureRow refProp={featureRef} mediaKey="demo1">
        <h2>{t("feature1.title")}</h2>
        <p>{t("feature1.body1")}</p>
        <p>{t("feature1.body2")}</p>
      </FeatureRow>
      <FeatureRow mediaKey="demo2">
        <h2>{t("feature2.title")}</h2>
        <p>{t("feature2.body1")}</p>
        <p>{t("feature2.body2")}</p>
      </FeatureRow>
      <FeatureRow mediaKey="demo3">
        <h2>{t("feature3.title")}</h2>
        <p>{t("feature3.body1")}</p>
        <p>{t("feature3.body2")}</p>
      </FeatureRow>
      <div className={css.featureBoxContainer}>
        <section className={css.featureBox}>
          <div className={css.imageBox}>
            <Image
              src="/asset/demo4.png"
              alt="demo4"
              width="300"
              height="240"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
          <h2>{t("feature4.title")}</h2>
          <p>{t("feature4.body1")}</p>
        </section>
        <section className={css.featureBox}>
          <div className={css.imageBox}>
            <Image
              src="/asset/demo5.png"
              alt="demo5"
              width="300"
              height="240"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
          <h2>{t("feature5.title")}</h2>
          <p>{t("feature5.body1")}</p>
        </section>
      </div>
      <section className={css.actionPrompt}>
        <h2>{t("foot.title")}</h2>
        <p>{t("foot.body1")}</p>
        <div className={css.buttonRow}>
          <OAuthPopup
            url="/api/invite"
            succeededUrl="/api/invited?"
            onSuccess={onInvite}
            className={css.main}
          >
            {t("head.button.add")}
          </OAuthPopup>
          <Link href="/news" className={css.sub}>
            {t("head.button.news")}
          </Link>
        </div>
      </section>
    </>
  );
}

function StatsBox() {
  const {
    data: { guildCount, userCount },
  } = useSWR("stats", fetcher, {
    revalidateOnFocus: false,
    fallbackData: { guildCount: null, userCount: null },
  });
  const t = useTranslations("home.stats");

  return (
    <div className={css.statsBox}>
      <h2>{t("title")}</h2>
      <ul>
        <li>
          <List size={36} />
          <span>{t("item1")}</span>
        </li>
        <li>
          <Hash size={36} />
          <span>{t("item2")}</span>
        </li>
        <li>
          <UserPlus size={36} />
          <span>{t("item3")}</span>
        </li>
        <li>
          <Shuffle size={36} />
          <span>{t("item4")}</span>
        </li>
        <li>
          <Star size={36} />
          <span>{t("item5")}</span>
        </li>
        <li>
          <Server size={36} />
          <span>
            {guildCount ? t("item6", { guildCount }) : <LoadingDots />}
          </span>
        </li>
        <li>
          <Users size={36} />
          <span>{userCount ? t("item7", { userCount }) : <LoadingDots />}</span>
        </li>
      </ul>
    </div>
  );
}

function FeatureRow({ refProp, children, mediaKey }) {
  const t = useTranslations("home");
  const { ref, inView } = useInView({
    triggerOnce: true,
  });
  const videoRef = useRef();
  const progressRef = useRef();
  const progressUpdaterRef = useRef();
  const [playbackState, setPlaybackState] = useState(0);
  const [neverPlayed, setNeverPlayed] = useState(true);

  useEffect(
    () => () => {
      clearInterval(progressUpdaterRef.current);
    },
    [],
  );

  const onPlay = () => {
    if (neverPlayed) setNeverPlayed(false);
    setPlaybackState(1);
    const onTimeUpdate = () => {
      if (!videoRef.current || !progressRef.current) return;
      const percentage =
        (videoRef.current.currentTime + 0.1) / videoRef.current.duration;
      progressRef.current.style.transform = `scaleX(${percentage})`;
    };
    onTimeUpdate();
    progressUpdaterRef.current = setInterval(onTimeUpdate, 75);
  };
  const onPause = () => {
    setPlaybackState(0);
    clearInterval(progressUpdaterRef.current);
  };

  const playing = playbackState === 1;

  return (
    <section ref={refProp} className={css.featureSection}>
      <div className={css.sectionBody}>{children}</div>
      <div className={css.mediaContainer}>
        <div ref={ref} className={css.videoBox}>
          <button
            type="button"
            className={playing ? css.pauseButton : css.playButton}
            onClick={() => {
              if (playing) {
                videoRef.current.pause();
              } else {
                videoRef.current.play();
              }
            }}
            aria-label={playing ? t("control.pause") : t("control.play")}
          >
            {!playing && (
              <>
                <Play aria-label={t("control.play")} />
                {t("control.play")}
              </>
            )}
          </button>
          <div ref={progressRef} className={css.progressBar} />
          {neverPlayed && (
            <Image
              src={`/asset/${mediaKey}.png`}
              alt={mediaKey}
              width="600"
              height="345"
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          )}
          {inView && (
            <video
              ref={videoRef}
              muted
              playsInline
              onPlay={onPlay}
              onPause={onPause}
              onEnded={() => setPlaybackState(3)}
              tabIndex="-1"
            >
              <source src={`/asset/${mediaKey}.mp4`} type="video/mp4" />
            </video>
          )}
        </div>
      </div>
    </section>
  );
}
FeatureRow.propTypes = {
  refProp: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(
        typeof Element === "undefined" ? () => {} : Element,
      ),
    }),
  ]),
  children: PropTypes.node.isRequired,
  mediaKey: PropTypes.string.isRequired,
};
FeatureRow.defaultProps = {
  refProp: undefined,
};

export async function getStaticProps({ locale }) {
  return {
    props: {
      messages: pick((await import(`locale/${locale}.json`)).default, [
        "common",
        "home",
      ]),
    },
  };
}

export default Home;
